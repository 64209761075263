// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-newsletter-js": () => import("./../src/pages/newsletter.js" /* webpackChunkName: "component---src-pages-newsletter-js" */),
  "component---src-pages-preview-js": () => import("./../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-pages-recipe-js": () => import("./../src/pages/recipe.js" /* webpackChunkName: "component---src-pages-recipe-js" */),
  "component---src-pages-shop-accept-js": () => import("./../src/pages/shop/accept.js" /* webpackChunkName: "component---src-pages-shop-accept-js" */),
  "component---src-pages-shop-cart-js": () => import("./../src/pages/shop/cart.js" /* webpackChunkName: "component---src-pages-shop-cart-js" */),
  "component---src-pages-shop-checkout-js": () => import("./../src/pages/shop/checkout.js" /* webpackChunkName: "component---src-pages-shop-checkout-js" */),
  "component---src-templates-article-category-page-js": () => import("./../src/templates/article-category-page.js" /* webpackChunkName: "component---src-templates-article-category-page-js" */),
  "component---src-templates-content-article-js": () => import("./../src/templates/content/article.js" /* webpackChunkName: "component---src-templates-content-article-js" */),
  "component---src-templates-content-recipe-js": () => import("./../src/templates/content/recipe.js" /* webpackChunkName: "component---src-templates-content-recipe-js" */),
  "component---src-templates-content-simple-page-js": () => import("./../src/templates/content/simple-page.js" /* webpackChunkName: "component---src-templates-content-simple-page-js" */),
  "component---src-templates-dynamic-front-page-js": () => import("./../src/templates/dynamic-front-page.js" /* webpackChunkName: "component---src-templates-dynamic-front-page-js" */),
  "component---src-templates-shop-collection-js": () => import("./../src/templates/shop/collection.js" /* webpackChunkName: "component---src-templates-shop-collection-js" */),
  "component---src-templates-shop-collection-structure-js": () => import("./../src/templates/shop/collection-structure.js" /* webpackChunkName: "component---src-templates-shop-collection-structure-js" */),
  "component---src-templates-shop-product-js": () => import("./../src/templates/shop/product.js" /* webpackChunkName: "component---src-templates-shop-product-js" */)
}

