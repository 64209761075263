import React from "react"
import ApolloClient from "apollo-boost"
import { ApolloProvider } from "@apollo/react-hooks"
import fetch from "isomorphic-fetch"
import "./src/styles/main.scss"
import "./assets/style.css"
import { StoreProvider } from "./src/context/store-context"

const client = new ApolloClient({
  uri: "https://2g2aoezl.api.sanity.io/v1/graphql/vera-com/default",
  credentials: "include",
  fetch,
})

export const wrapRootElement = ({ element }) => (
  <ApolloProvider client={client}>
    <StoreProvider>{element}</StoreProvider>
  </ApolloProvider>
)
