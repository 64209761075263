import React, { createContext, useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"

const store = {
  isCartOpen: false,
  cart: [],
  currentDelivery: {},
  code: {},
  setCurrentDelivery: () => {},
  serializeCart: () => {},
  loadCart: () => {},
  addToCart: () => {},
  removeFromCart: () => {},
  fullPrice: () => {},
  clearCart: () => {},
  setCode: () => {},
  freeDeliveryCap: 0,
}

export const StoreContext = createContext(store)

export const StoreProvider = ({ children }) => {
  const { sanitySiteInfo } = useStaticQuery(graphql`
    query {
      sanitySiteInfo(site_id: { current: { eq: "veracom" } }) {
        shop_configuration {
          free_delivery_cap
        }
      }
    }
  `)
  const [cart, setCart] = useState([])
  const [code, setCode] = useState(null)

  const addToCart = item => {
    if (process.env.NODE_ENV === `production` && typeof fbq === `function`) {
      fbq("track", "AddToCart") // eslint-disable-line
    }
    setCart([...cart, item])
  }

  const serializeCart = () => JSON.stringify(cart)

  const loadCart = newCart => {
    setCart(newCart)
  }

  const clearCart = () => setCart([])

  const removeFromCart = item => {
    const toRemove = cart.find(
      product => product.variant._key === item.variant._key
    )
    const index = cart.indexOf(toRemove)
    const newCart = cart
    if (index > -1) {
      newCart.splice(index, 1)
    }
    setCart([...newCart])
  }

  const fullPrice = () =>
    cart.reduce((acc, curr) => acc + curr.variant.variant_info_price, 0)

  useEffect(() => {
    localStorage.setItem("current_cart", JSON.stringify(cart))
  }, [cart])

  return (
    <StoreContext.Provider
      value={{
        ...store,
        addToCart,
        removeFromCart,
        fullPrice,
        clearCart,
        serializeCart,
        loadCart,
        setCode,
        code,
        cart,
        freeDeliveryCap: sanitySiteInfo.shop_configuration.free_delivery_cap,
      }}
    >
      {children}
    </StoreContext.Provider>
  )
}
